.sheet_table {
  border: 2px solid black;
  border-radius: 10px;
  font-family: "Gilroy-Regular";
  /* padding: 10px 0px; */
}
.card_class {
  /* background-color: blueviolet; */
}

.name-align-left {
  text-align: left !important;
}

.name-head {
  width: 225px;
}

.admNo {
  width: 150px;
}

.card_class::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.card_class::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background-color: #671e78 !important;
}

.card_class::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  display: block !important;
  visibility: visible !important;
  /* background-color: green !important; */
}
.sheet_table table,
.sheet_table table tr {
  background-color: white !important;
}

.sheet_table table::-webkit-scrollbar {
  /* display: none; */
}

.sheet_table table::-webkit-scrollbar-track {
  background-color: blue;
}
.sheet_table table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: red;
}

.sheet_table table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: green;
}
.sheet_table table {
  border-collapse: collapse;
}
.sheet_table tr:first-child th {
  border-bottom: 2px solid black;
  border-collapse: collapse;
  font-size: 12px;
  border-right: 2px solid black;
  /* height: 142px; */
}

.sheet_table th:last-child {
  border-right: none;
}
.sheet_table tr {
  border-bottom: 2px solid black;
  border-collapse: collapse;
}
.sheet_table td {
  border: 2px solid black;
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
}
.sheet_table td:first-child {
  border-left: 0px solid black;
}
.sheet_table td:last-child {
  border: none;
}

.sheeet_table table {
  border: 2px solid black !important;
  padding-bottom: 0px;
  border-color: black !important;
}

.sheeet_table tr:first-child th {
  border-bottom: 2px solid black;
  border-collapse: collapse;
  font-size: 16px;
  border-right: 2px solid black;
  padding: 9px;
  font-weight: 600;
}

.sheeet_table .att th {
  padding: 0 !important;
}

.sheeet_table td,
.sheeet_table th {
  padding: 10px 0px 10px 4px !important;
  border: 1px solid black;
  text-align: center;
}

.sheeet2_table td,
.sheeet2_table th {
  padding: 0 !important;
  border: 1px solid black;
  text-align: center;
}

.transform_head {
  /* transform: rotate(-90deg); */
  /* width: 58px; */
  text-align: center !important;
  /* width: max-content !important; */
}
/* 
.transform_head > div {
  width: max-content;
} */

@media (max-width: 588px) {
  .sheet_table th {
    font-size: 10px;
    padding: 5px;
  }
}

.result_table th {
  font-size: 13px;
  color: #000;
}
.result_table td {
  font-size: 12px;
  padding-left: 10px;
  color: #000;
}

.fourth-child {
  border-right: 2px solid red !important;
}
