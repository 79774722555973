select{
 
    outline: 0;
    border: 1px solid #671E78;
    font-family: gilroy-regular;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;

}
select:hover::after {
    color: #F39C12;
}
 