/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("../assets/fonts/Gilroy-Bold.woff"),
    url("../assets/fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("../assets/fonts/Gilroy-Bold.woff"),
    url("../assets/fonts/Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("../assets/fonts/Gilroy-SemiBold.ttf"),
    url("../assets/fonts/Gilroy-SemiBold.ttf") format("woff");
}

@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: normal;
  src: local("../assets/fonts/Gilroy-Light.woff"),
    url("../assets/fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("../assets/fonts/Gilroy-Medium.woff"),
    url("../assets/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("../assets/fonts/Gilroy-Regular.woff"),
    url("../assets/fonts/Gilroy-Regular.woff") format("woff");
}

.special-label {
  display: none;
  color: #0000001a;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.previous,
.next {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 22px;
  cursor: pointer;
  height: 22px;
  text-align: center;
  border: 1px solid #671e78;
  border-radius: 50px;
}

.previous > a > svg {
  fill: #671e78 !important;
}
.next > a > svg {
  fill: #671e78 !important;
}
.page-link {
  width: 22px;
  cursor: pointer;
  height: 22px;
  border: 1px solid #671e78;
  border-radius: 50px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-link:hover {
  background: #671e78;
}
.page-link > a {
  color: #671e78 !important;
}
.page-link:hover > a,
.page-link > a:hover {
  color: white !important;
}
.active-page-link,
.active-page-link > a {
  background: #671e78;
  color: white !important;
  border-radius: 50%;
}
