* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
div::-webkit-scrollbar {
  display: none;
}

div::-moz-progress-bar,
div::-moz-scrollbar {
  display: none;
}

div {
  scrollbar-width: none;
}
