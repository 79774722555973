@tailwind base;
@tailwind components;
@tailwind utilities;


.modal{
    z-index: 1500;
}

.countryCode .react-tel-input {
    width: 8% !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid rgb(103, 30, 120) !important;
    padding: 1.44rem 0.9385rem;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    margin-top: 5px;
    background-color: white !important;
}

.countryCode .react-tel-input .flag-dropdown {
    background-color: white !important;
    border: none !important;
}

.countryCode .Phone-Input{
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}